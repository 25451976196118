import i18n from 'i18next';
import deTranslation from './de/de.json';
import enTranslation from './en/en.json';
import deLanguages from './de/de.languages.json';
import enLanguages from './en/en.languages.json';
import deDateRangePicker from './de/de.daterangepicker.json';
import enDateRangePicker from './en/en.daterangepicker.json';
import deMetrics from './de/de.metrics.json';
import enMetrics from './en/en.metrics.json';
import deTour from './de/de.tour.json';
import enTour from './en/en.tour.json';
import deSubscription from './de/de.subscription.json';
import enSubscription from './en/en.subscription.json';
import deWelcome from './de/de.welcome.json';
import enWelcome from './en/en.welcome.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Initialize i18n with translations
i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {},
		interpolation: { escapeValue: false },
		// Set default language
		fallbackLng: 'en',
		supportedLngs: ['de', 'en'],
		resources: {
			de: {
				translation: deTranslation,
				languages: deLanguages,
				daterangepicker: deDateRangePicker,
				metrics: deMetrics,
				tour: deTour,
				subscription: deSubscription,
				welcome: deWelcome,
			},
			en: {
				translation: enTranslation,
				languages: enLanguages,
				daterangepicker: enDateRangePicker,
				metrics: enMetrics,
				tour: enTour,
				subscription: enSubscription,
				welcome: enWelcome,
			},
		},
	});
